import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, forkJoin, lastValueFrom, map, Observable, of, Subscription, tap, throwError } from 'rxjs';
import { Call, TIMETYPE, TIMETYPEVALUE } from 'src/app/buisness-object/call/Call';
import { CallForm } from 'src/app/buisness-object/call/form/CallForm';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { User } from 'src/app/buisness-object/user/User';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { PhoneCallService } from 'src/app/service/phone-call/phone-call.service';
import { PhonelistTicketsComponent } from '../phonelist-tickets/phonelist-tickets.component';

@Component({
  selector: 'app-phonelist-details',
  templateUrl: './phonelist-details.component.html',
  styleUrls: ['./phonelist-details.component.scss']
})
export class PhonelistDetailsComponent implements OnInit, OnDestroy {

  @Input() calls: Call[] = [];
  @Input() users: User[] = [];
  @Input() customers: Customer[] = [];
  @Output() removeCallEmitter = new EventEmitter<any>();
  public routeSubscription: Subscription;
  public selectedCall: Call;
  public infoActive = false;
  public selectPriorities = [
    { label: 'Hoch', value: 0 },
    { label: 'Mittel', value: 1 },
    { label: 'Niedrig', value: 2 }
  ];
  public timeTyp: string;
  @ViewChild('appPhonelistTicket') appPhonelistTicket: PhonelistTicketsComponent;

  constructor(
    private customerService: CustomerService,
    private activateRoute: ActivatedRoute,
    private phoneCallService: PhoneCallService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.setRouteSubscriptions();
    const params = this.activateRoute.snapshot.queryParams;
  }

  ngOnDestroy(): void {
    if(this.routeSubscription){
      this.routeSubscription.unsubscribe();
    }
  }

  async setUp(callId: number) {
    if(this.calls.find((c) => c.call_id == callId) == null){
      return;
    }
    this.selectedCall = await lastValueFrom(this.getPhoneCall(callId));
    this.timeTyp = TIMETYPEVALUE[this.selectedCall.time_type];
    this.selectedCall.user = this.users.find((u) => u.userId == this.selectedCall.user_id);
    this.selectedCall.user_mp3 = this.users.find((u) => u.userId == this.selectedCall.mp3_user_id);
  }

  setRouteSubscriptions() {
    this.routeSubscription = this.activateRoute.queryParams.subscribe((params) => {
      this.selectedCall = null;
      const callId = Number(params['callId']);
      if(callId){
        this.setUp(callId);
      } else if(this.calls.length > 0) {
        this.setUp(this.calls[0].call_id)
      }
     })
  }

  getPhoneCall(call_id: number): Observable<Call> {
    return this.phoneCallService.getPhoneCall(call_id).pipe(
      map((result) => {
        return result;
      }), catchError((error) => {
        console.log(error);
        return of(null);
      })
    );
  }

  // requestDataServerside(): Observable<any> {
  //   return forkJoin({
  //     customers: this.customerService.getCustomers(),
  //   }).pipe(
  //     tap((result) => {
  //       if(result) {
  //         this.customers = result.customers;
  //       }
  //     }),
  //     catchError((error) => {
  //       console.error(error);
  //       return null;
  //     })
  //   );
  // }

  removeCallFromList(call: Call) {
    this.phoneCallService.deletePhoneCall(call.call_id).subscribe((success) => {
      if(success) {
        this.removeCallEmitter.emit(call);
      }
    })
  }

  selectionMedia() {

  }

  onMediaError() {
    console.error('Media failed to load.');
  }
}
