import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, firstValueFrom, interval } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginService } from '../login/login.service';
import { DialogService } from '../dialog/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  private dialogSubscription: Subscription;

  constructor(
    private ngZone: NgZone,
    private location: Location,
    private router: Router,
    private http: HttpClient,
    private loginService: LoginService,
    private dialogService: DialogService
  ) {
    this.checkForUpdate();
  }

  async checkForUpdate() {
    if(!environment.production){
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      interval(1000*60*60*8)
        .subscribe(async () => {
          this.ngZone.run(async () => {
            try {
              const baseUrl = `${window.location.origin}${this.location.prepareExternalUrl(this.router.url)}`;
              const rootPath = baseUrl.split('#')[0];
              const cacheBuster = new Date().getTime();
              const path = `${rootPath}/assets/version.json?cb=${cacheBuster}`;
              const clientVersion = environment.VERSION;
              const serverVersion = await firstValueFrom(
                this.http.get<{ version: string }>(path)
              );
              if(clientVersion && clientVersion !== serverVersion.version) {
                this.promptUser(serverVersion.version);
              }
            } catch (err) {
              console.error('Failed to check for updates:', err);
            }
          });
        });
    });
  }

  private promptUser(version: string): void {
    this.dialogSubscription = this.dialogService.closeInformationDialog$.subscribe((result) => {
      if(result?.submit_value == 'confirm_version_update'){
        setTimeout(() => {
          this.dialogSubscription.unsubscribe();
          // localStorage.clear();
          // sessionStorage.clear();
          // caches.keys().then(names => names.forEach(name => caches.delete(name)));
          window.location.reload();
        }, 300);
      }
    })
    this.dialogService.openInformation(
      {
        title: 'Neue Version verfügbar!',
        message: `Version ${version}. Seite wird neu geladen. Falls du gerade ein Ticket schreibst, dann tut es mir leid. Beschwerden an Maggi.`,
        btn_submit_txt: 'Ok',
        submit_value: 'confirm_version_update'
      }
    );
  }
}
